import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';
import Contact from './components/Contact';
import Footer from './components/Footer';
import About from './components/About';

import './App.css';

function App() {
  return (
    <Router>
      <div className='app-container'>
        <Navbar />
        <div className="content">

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About/>}/>
          
        </Routes>
        </div>
        
        <Footer/>
      </div>
    </Router>
  );
}


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <h1>📸 FOCUS CLICK! CLICK!</h1>
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {/* Hamburger Icon */}
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${isOpen ? 'show' : ''}`}>
        <li><Link to="/" onClick={toggleMenu}>Photos</Link></li>
        <li><Link to="/contact" onClick={toggleMenu}>Pricing</Link></li>
        <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
        
      </ul>
    </nav>
  );
};


export default App;
